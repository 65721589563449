import React from "react"
import styled from "styled-components"

type Props = {
  src: string
}

const Img = styled.img`
  visibility: hidden;
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    visibility: visible;
  }
`

const LongReferenceImage: React.FC<Props> = ({ src }) => {
  return (
    <>
      <Img src={src} loading="lazy" alt="" width="100%" height="auto" />
    </>
  )
}

export default LongReferenceImage
