import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"

import { Medailon, PaddingWrapper, Quote } from "@igloonet-web/shared-ui"
import Middle from "../../../images/reference/study-in-brno/middle.jpg"
import Middle2 from "../../../images/reference/study-in-brno/middle2.png"
import Logo from "../../../images/reference/study-in-brno/logo.svg"
import Top from "../../../images/reference/study-in-brno/top.jpg"
import Gurnik from "../../../images/reference/study-in-brno/gurnik.png"
import Study from "../../../images/reference/study-in-brno/study.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import LongReferenceImage from "../../../components/reference-detail/long-reference-image"

const Sib = () => (
  <Layout>
    <Helmet>
      <title>Reference na marketingové práce pro Grohe | igloonet.cz</title>
      <meta
        name="description"
        content="Konzultace a&nbsp;mentoring interních týmů pro klienty řešíme celkem často,
            ale tato akce pro finanční manažery pro nás byla svým záběrem i&nbsp;krátkou dobou
            na realizaci specifická."
      />
    </Helmet>

    <ReferenceHeader
      companyName="STUDY IN BRNO"
      heading="Pomáháme brněnským univerzitám oslovit zahraniční studenty"
      date="září 2020 - současnost"
    />

    <ReferenceImageText
      right
      img={Top}
      eager
      alt=""
      logo={Logo}
      logoAlt="logo Study in Brno"
      link="//study-in-brno.cz/"
    >
      <p>
        <strong>Study in Brno</strong> je společný projekt brněnských univerzit,
        který do Brna láká nové zahraniční studenty. My mu pomáháme v&nbsp;tom,
        aby měl <strong>co nejúspěšnější digitální propagaci</strong>.
        Momentálně se specializujeme hlavně na práci s&nbsp;interními týmy,
        a&nbsp;tak jsme uspořádali{" "}
        <strong>
          sérii individuálních školení pro specialisty i&nbsp;manažery
        </strong>
        .
      </p>
      <Quote>
        <strong>Study in Brno</strong> založili lidé ze zahraničních týmů tří
        univerzit (Masarykova univerzita, Mendelova univerzita a&nbsp;Vysoké
        učení technické). Cíl je jednoduchý - jednotně oslovit potenciální
        zahraniční studenty a&nbsp;představit Brno jako ideální studentské
        město.
      </Quote>
    </ReferenceImageText>

    <PaddingWrapper>
      <ReferenceImageText
        halfImgSize
        img={Middle}
        alt="Dva studenti prohlížející výrobek před řadou 3D tiskáren v univerzitní laboratoři."
      >
        <p>
          Na začátku jsme se rozhodli pro <strong>PPC kampaně</strong> ve
          vyhledávání, bannerovou reklamu a&nbsp;remarketing. Rychle tak
          ověříme, jaký je v&nbsp;jednotlivých zemích potenciál. U&nbsp;každého
          projektu také řešíme,{" "}
          <strong>
            jestli dává větší smysl, abychom kampaně spravovali my, nebo
            klientův interní tým
          </strong>
          . Pokud je marketingové oddělení stabilní a&nbsp;chce se dál
          zlepšovat, raději pracujeme přímo s&nbsp;ním
        </p>
        <p>
          Navrhli jsme proto <strong>sérii školení/workshopů</strong> na práci
          s&nbsp;Google Ads, Facebook Ads a&nbsp;na vyhodnocování aktivit
          (hlavně práce s&nbsp;Google Analytics). Aby byly opravdu na míru, mezi
          účastníky jsme nejdřív provedli{" "}
          <strong>průzkum toho, co v&nbsp;oblasti e-commerce už umí</strong> a
          znají.
        </p>
      </ReferenceImageText>
    </PaddingWrapper>

    <ReferenceImageText
      halfImgSize
      right
      img={Middle2}
      alt="Barevné znázornění log Masarykovy univerzity, VUT a Mendelovy univerzity nad fotografií města Brna."
    >
      <p>
        Školení jsme primárně připravili pro lidi, kteří se budou o&nbsp;kampaně
        dál starat.{" "}
        <strong>Zohlednili jsme však i&nbsp;manažerskou rovinu</strong> a&nbsp;s
        vedoucími jednotlivých útvarů probírali, jak nad kampaněmi přemýšlet
        a&nbsp;jak je následně vyhodnocovat.
      </p>

      <p>
        Ze zkušenosti víme, že{" "}
        <strong>
          pokud klienta pouze proškolíme a&nbsp;už s&nbsp;ním dál nepracujeme,
          výsledek je přinejlepším poloviční
        </strong>
        . Razíme přístup „learning by doing,” a&nbsp;tak s&nbsp;podporou
        interního týmu pokračujeme. Markeťáky jsme nechali vytvořit první
        kampaně, dali k&nbsp;nim zpětnou vazbu a&nbsp;průběžně konzultujeme
        další postup. Díky tomuto zvyšujeme šance, že bude dobrá nejen tato
        kampaň, ale stejně úspěšné budou i&nbsp;ty další.
      </p>
    </ReferenceImageText>

    <PaddingWrapper>
      <LongReferenceImage src={Study} />

      <Medailon
        personName="David Gurník"
        position="Manažer pro internacionalizaci VUT"
        img={Gurnik}
      >
        <p>
          Naučili jsme se dělat reklamy, za které se nemusíme stydět. Kolegové z
          igloonet se nebojí sdílet své zkušenosti, být na nápomocni v krizových
          situacích a odolní vůči nekonečným dotazům. Konečně někdo, kdo pro nás
          udělal efektivní sérii školení a přemýšlí nad naším projektem stejně
          tak, jako by byl jejich vlastní
        </p>
      </Medailon>
    </PaddingWrapper>

    <ContactForm contact="adam" />
  </Layout>
)

export default Sib
